import { FormEvent, useEffect, useRef, useState } from "react";
import {
    Button,
    Flex,
    FormControl,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Accordion,
    useToast,
    Divider,
    Text,
    Checkbox,
    Heading
} from "@chakra-ui/react"
import bgLogo from "../assets/Isolation_Mode.svg"
import arrowRight from "../assets/icons/arrow_forward.svg"
import { credentialsRepo } from "../api/credentialsRepo";
import { format } from "date-fns"
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import Loading from "../components/Loading";
import { AxiosResponse } from "axios";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";
import "../assets/styles/searchInfoStealer.css"

interface StealerLog {
    uuid: string;
    antivirus: string;
    log_date: string | undefined;
    path: string;
    stealer_name: string;
}

interface CredentialSearchResponse {
    credentials: Credential[];
    current_cred_page: number;
    total_cred_pages: number;
    total_credentials: number;
}
interface Credential {
    uuid: string;
    software: string;
    host: string;
    domain: string;
    email_domain: string;
    local_part: string;
    password: string;
    username: string;
}

interface System {
    uuid: string;
    computer_name: string;
    country: string;
    hardware_id: string;
    ip_address: string;
    machine_id: string;
    machine_user: string;
    stealer_log: StealerLog;
}

interface SystemSearchResponse {
    systems: System[];
    total_systems: number;
    total_pages: number;
    current_page: number;
}

export default function SearchInfoStealer() {
    const [search, setSearch] = useState<string>("");
    const [searchResults, setSearchResults] = useState<SystemSearchResponse | undefined>();
    const [credentialResults, setCredentialResults] = useState<{ [key: string]: CredentialSearchResponse } | undefined>();
    const [credentialsSearchAll, setCredentialsSearchAll] = useState<boolean>(false)
    const [searchBy, setSearchBy] = useState<"domain" | "email" | "ip">("domain");
    const pulseRef = useRef<HTMLDivElement>(null);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [systemsPage, setSystemsPage] = useState(1);
    const [credsPage, setCredsPage] = useState(1);
    const [currentOpenedSystem, setCurrentOpenedSystem] = useState<string | undefined>()

    async function fetchResults() {
        try {
            setIsLoading(true);
            let results: AxiosResponse<SystemSearchResponse>;
            if (searchBy === "email") {
                results = await credentialsRepo.searchEmailDomain(search.toLowerCase(), systemsPage);
                results.data.systems.forEach(system => {
                    credentialsRepo.searchCredentialsEmailDomain(system.uuid, search.toLowerCase(), 1).then((res: AxiosResponse<CredentialSearchResponse>) => {
                        setCredentialResults((prevResults) => ({
                            ...prevResults,
                            [system.uuid]: res.data,
                        }));
                    });
                })
            } else if (searchBy === "domain") {
                results = await credentialsRepo.searchDomain(search.toLowerCase(), systemsPage);
                results.data.systems.forEach(system => {
                    credentialsRepo.searchCredentialsDomain(system.uuid, search.toLowerCase(), 1).then((res: AxiosResponse<CredentialSearchResponse>) => {
                        setCredentialResults((prevResults) => ({
                            ...prevResults,
                            [system.uuid]: res.data,
                        }));
                    });
                })
            } else {
                results = await credentialsRepo.searchIP(search.toLowerCase(), systemsPage);
                results.data.systems.forEach(system => {
                    credentialsRepo.searchCredentialsAll(system.uuid, 1).then((res: AxiosResponse<CredentialSearchResponse>) => {
                        setCredentialResults((prevResults) => ({
                            ...prevResults,
                            [system.uuid]: res.data,
                        }));
                    });
                })
            }
            setIsLoading(false);
            setSearchResults(results.data);
        } catch (e: any) {
            toast({
                title: "No results found",
                status: "error"
            });
            setIsLoading(false);
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        fetchResults();
    }

    useEffect(() => {
        if (searchResults) {
            fetchResults();
        }
    }, [systemsPage])

    useEffect(() => {
        if (credentialResults && currentOpenedSystem) {
            if (credentialsSearchAll === true || searchBy === "ip") {
                credentialsRepo.searchCredentialsAll(currentOpenedSystem, credsPage).then((res: AxiosResponse<CredentialSearchResponse>) => {
                    setCredentialResults((prevResults) => ({
                        ...prevResults,
                        [currentOpenedSystem]: res.data,
                    }));
                })
            } else if (searchBy === "email") {
                credentialsRepo.searchCredentialsEmailDomain(currentOpenedSystem, search.toLowerCase(), credsPage).then((res: AxiosResponse<CredentialSearchResponse>) => {
                    setCredentialResults((prevResults) => ({
                        ...prevResults,
                        [currentOpenedSystem]: res.data,
                    }));
                });
            } else if (searchBy === "domain") {
                credentialsRepo.searchCredentialsDomain(currentOpenedSystem, search.toLowerCase(), credsPage).then((res: AxiosResponse<CredentialSearchResponse>) => {
                    setCredentialResults((prevResults) => ({
                        ...prevResults,
                        [currentOpenedSystem]: res.data,
                    }));
                });
            }
        }
    }, [credsPage, credentialsSearchAll])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            background: `url(${bgLogo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            minHeight: "100vh",
            backgroundSize: "contain"
        }}>
            <Loading show={isLoading} />
            <div className="content-wrapper" style={{ width: "81%", rowGap: "0px" }}>
                <Flex className="credentials-search-switch">
                    <Link to="/credentials" className="active"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Credentials</Heading></Link>
                    <Link to="/combolist"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Combolist</Heading></Link>
                </Flex>
                <form onSubmit={handleSubmit}>
                    <FormControl ref={pulseRef}>
                        <InputGroup className="searchInputWrapper1">
                            <Input
                                onChange={(e) => setSearch((e.target.value))}
                                value={search}
                                placeholder={searchBy === "email" ? 'Search by email domain' : (searchBy === "domain" ? 'Search by domain' : "Search by IP")}
                                size='lg'
                            />
                            <InputRightElement className="input-string-indicator" style={{ height: "100%", opacity: search.length > 3 ? 1 : 0 }}>
                                <Button type="submit" className="button-submit1" borderRightRadius={"4px"} height="100%">Search<Image src={arrowRight} /></Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </form>

                <Flex alignItems="stretch" flexDir={"column"} mt={2} mb={4}>
                    {/* <FormLabel htmlFor="search-type-switch" mb="0">
                        Search by:
                    </FormLabel> */}
                    <Flex columnGap={"30px"}>
                        <div
                            style={{ width: "fit-content", lineHeight: "revert" }}
                            className={"suggestion-wrapper " + (searchBy === "domain" ? "active" : "")}
                            onClick={() => setSearchBy("domain")}>
                            <Text fontSize='md'>Domain</Text>
                        </div>
                        <div
                            style={{ width: "fit-content", lineHeight: "auto" }}
                            className={"suggestion-wrapper " + (searchBy === "email" ? "active" : "")}
                            onClick={() => setSearchBy("email")}>
                            <Text fontSize='md'>Email Domain</Text>
                        </div>
                        <div
                            style={{ width: "fit-content", lineHeight: "auto" }}
                            className={"suggestion-wrapper " + (searchBy === "ip" ? "active" : "")}
                            onClick={() => setSearchBy("ip")}>
                            <Text fontSize='md'>IP</Text>
                        </div>
                    </Flex>
                </Flex>

                {searchResults && (
                    <>
                        {/* <Box mt={8} width="100%">
                            <VStack spacing={4} align="stretch">
                                <Heading size="md">Search Results</Heading>
                                <Text>Total Systems: {searchResults.total_systems}</Text>
                                <Accordion allowMultiple>
                                    {searchResults.systems.map((system: any) => (
                                        <AccordionItem key={system.system_info.uuid}>
                                            <h2>
                                                <AccordionButton _expanded={{ background: "rgba(34, 34, 34, 1)" }}>
                                                    <Box flex="1" textAlign="left">
                                                        {system.system_info.computer_name} - {system.system_info.ip_address}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4} backgroundColor="rgba(34, 34, 34, 1)">
                                                <Table variant="simple" size="sm">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Log Date</Th>
                                                            <Th>Country</Th>
                                                            <Th>Machine User</Th>
                                                            <Th>Antivirus</Th>
                                                            <Th>Stealer Name</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                            <Td>{format(system.system_info.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss")}</Td>
                                                            <Td>{system.system_info.country}</Td>
                                                            <Td>{system.system_info.machine_user}</Td>
                                                            <Td>{system.system_info.stealer_log.antivirus}</Td>
                                                            <Td>{system.system_info.stealer_log.stealer_name}</Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                                <Heading size="sm" mt={4} mb={2}>Credentials</Heading>
                                                <Table variant="simple" size="sm">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Software</Th>
                                                            <Th>Host</Th>
                                                            <Th>Email Domain</Th>
                                                            <Th>Username</Th>
                                                            <Th>Password</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {system.credentials.map((cred: any) => (
                                                            <Tr key={cred.uuid}>
                                                                <Td>{cred.software}</Td>
                                                                <Td>{cred.host}</Td>
                                                                <Td>{cred.email_domain}</Td>
                                                                <Td>{cred.username}</Td>
                                                                <Td>{cred.password}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </VStack>
                        </Box> */}
                        <Text marginTop="12px" color="#818181" letterSpacing={"0.12em"}>Total systems: {searchResults.total_systems}</Text>
                        <Accordion allowToggle width={"100%"}>
                            {searchResults && searchResults.systems.map((systemObj, i) =>
                                <CustomAccordionItem key={i}
                                    accordionTitle={systemObj.computer_name}
                                    accordionText={`${systemObj.ip_address || "Unknown"} - Credentials: ${(credentialResults && credentialResults[systemObj.uuid] ? credentialResults[systemObj.uuid].total_credentials : "Loading...")} - Log Date: ${systemObj.stealer_log.log_date ? format(systemObj.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss") : "Unknown"} - ${systemObj.country} - Machine User: ${systemObj.machine_user} - Antivirus: ${systemObj.stealer_log.antivirus} - Logstealer: ${systemObj.stealer_log.stealer_name}`}
                                    // accordionText={systemObj.system_info.machine_user || "Unknown"}
                                    onClick={() => { setCurrentOpenedSystem(systemObj.uuid) }}>
                                    <>
                                        <Checkbox variant={"subtle"} onChange={(e) => {
                                            setCredentialsSearchAll(e.target.checked);
                                            setCredsPage(1);
                                        }}>
                                            Show All Credentials
                                        </Checkbox>
                                        {credentialResults && credentialResults[systemObj.uuid] && <CustomTable
                                            headings={["SOFTWARE", "HOST", "EMAIL DOMAIN", "USERNAME", "PASSWORD"]}
                                            rowsData={
                                                credentialResults[systemObj.uuid].credentials.map(cred => [cred.software, cred.host, cred.email_domain, cred.username, cred.password])
                                            } />}
                                        <Divider />
                                        {/* {window.innerWidth < 768 && <CustomTable
                                            headings={["Log Date", "IP", "Country", "Machine User", "Antivirus", "Stealer Name"]}
                                            rowsData={
                                                [[
                                                    systemObj.stealer_log.log_date ? format(systemObj.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss") : "Unknown",
                                                    systemObj.ip_address || "Unknown",
                                                    systemObj.country,
                                                    systemObj.machine_user,
                                                    systemObj.stealer_log.antivirus,
                                                    systemObj.stealer_log.stealer_name
                                                ]]
                                            } />} */}
                                        {credentialResults && credentialResults[systemObj.uuid] && <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                            <Pagination
                                                currentPage={credsPage}
                                                setPage={setCredsPage}
                                                maxPage={credentialResults[systemObj.uuid].total_cred_pages}
                                            />
                                        </Flex>}
                                    </>
                                </CustomAccordionItem>
                            )}
                        </Accordion>
                        {searchResults && <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                            <Pagination
                                currentPage={systemsPage}
                                setPage={setSystemsPage}
                                maxPage={searchResults.total_pages}
                            />
                        </Flex>}
                    </>
                )}

                {/* <Flex className="home-footer" alignItems={"center"} justifyContent={"space-between"} position="fixed" bottom="24px" left="24px" right="24px">
                    <Image src={HomeFooterLogo} h={"48px"} />
                    <Flex columnGap={"45px"}>
                        <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>CONTACT SUPPORT</Text>
                        <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>© 2024 Sphere by Alphatechs</Text>
                    </Flex>
                </Flex> */}
            </div>
        </div>
    )
}