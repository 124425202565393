
import { FormControl, InputGroup, Input, InputRightElement, Button, Image, Flex, Heading, Accordion, Text } from "@chakra-ui/react";
import bgLogo from "../assets/Isolation_Mode.svg"
import arrowRight from "../assets/icons/arrow_forward.svg"
import Loading from "../components/Loading";
import { FormEvent, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { credentialsRepo } from "../api/credentialsRepo";
import { Link } from "react-router-dom";
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import Pagination from "../components/Pagination";

interface CombolistsSearchResults {
    url_login: string;
    source: string;
    doc_date: string;
}

interface CombolistsSearchResponse {
    total: number;
    page: number;
    "total pages": number;
    results: CombolistsSearchResults[]
}

export default function SearchComboLists() {
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<CombolistsSearchResponse | undefined>();
    const [page, setPage] = useState<number>(1);
    const pulseRef = useRef<HTMLDivElement>(null);

    async function fetchResults() {
        try {
            setIsLoading(true);
            let results: AxiosResponse<CombolistsSearchResponse>;
            results = await credentialsRepo.searchComboList(search, page)

            setIsLoading(false);
            setSearchResults(results.data);
        } catch (e: any) {
            toast({
                title: "No results found",
                status: "error"
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(searchResults) fetchResults();
    }, [page])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            background: `url(${bgLogo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            minHeight: "100vh",
            backgroundSize: "contain"
        }}>
            <Loading show={isLoading} />
            <div className="content-wrapper" style={{ width: "81%", rowGap: "0px" }}>
                <Flex className="credentials-search-switch">
                    <Link to="/credentials"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Credentials</Heading></Link>
                    <Link to="/combolist" className="active"><Heading className={"nav-heading"} size="sm" fontWeight={400}>Combolist</Heading></Link>
                </Flex>
                <form onSubmit={async (e: FormEvent) => {
                    e.preventDefault();
                    fetchResults();
                }}>
                    <FormControl ref={pulseRef}>
                        <InputGroup className="searchInputWrapper1">
                            <Input
                                onChange={(e) => setSearch((e.target.value))}
                                value={search}
                                placeholder={'Search Combolist'}
                                size='lg'
                            />
                            <InputRightElement className="input-string-indicator" style={{ height: "100%", opacity: search.length > 3 ? 1 : 0 }}>
                                <Button type="submit" className="button-submit1" borderRightRadius={"4px"} height="100%">Search<Image src={arrowRight} /></Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </form>

                {searchResults && (
                    <>
                        <Text marginTop="12px" color="#818181" letterSpacing={"0.12em"}>Total credentials: {searchResults?.total}</Text>
                        <Accordion allowToggle width={"100%"}>
                            {searchResults && searchResults.results.map((systemObj, i) =>
                                <CustomAccordionItem key={i}
                                    accordionTitle={systemObj.source}
                                    accordionText={`Date: ${systemObj.doc_date}`}
                                    // accordionText={systemObj.system_info.machine_user || "Unknown"}
                                    onClick={() => { }}>
                                    <CustomTable
                                        headings={["Date", "Login Url"]}
                                        rowsData={
                                            [[systemObj.doc_date, systemObj.url_login]]
                                        } />
                                </CustomAccordionItem>)}
                        </Accordion>
                        <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                            <Pagination
                                currentPage={page}
                                setPage={setPage}
                                maxPage={searchResults["total pages"]}
                            />
                        </Flex>
                    </>
                )}
            </div>
        </div>
    );

}

function toast(arg0: { title: string; status: string; }) {
    throw new Error("Function not implemented.");
}
